import fileSaver from 'file-saver';
import axios from './axios';
import { AxiosResponse } from 'axios';

const FILE_DOWNLOAD_ACCEPT_HEADER = { Accept: 'application/pdf, text/csv, application/vnd.ms-excel' };

const getFileByRequest = async (url: string, params?: any): Promise<any> => {
  return new Promise<void>((resolve) => {
    axios
      .get(url, { params, responseType: 'blob', headers: FILE_DOWNLOAD_ACCEPT_HEADER })
      .then((response) => {
        openFileResponse(response);
        resolve();
      })
      .catch((error) => {
        console.error(error);
        resolve();
      });
  });
};

export const getFileByPostRequest = async (url: string, data: any, params?: any): Promise<any> => {
  return new Promise<void>((resolve) => {
    axios
      .post(url, data, {
        params,
        responseType: 'blob',
        headers: FILE_DOWNLOAD_ACCEPT_HEADER
      })
      .then((response) => {
        openFileResponse(response);
        resolve();
      })
      .catch((error) => {
        console.error(error);
        resolve();
      });
  });
};

const openFileResponse = (response: AxiosResponse) => {
  let filename = 'report.pdf';
  let type = 'application/pdf';

  if (response.headers['content-disposition']) {
    const filenameSplit = response.headers['content-disposition'].split('=');
    if (filenameSplit.length >= 2) {
      filename = filenameSplit[1];
    }
  }

  if (response.headers['content-type']) {
    type = response.headers['content-type'];
  }
  const blob = new Blob([response.data], { type });

  fileSaver.saveAs(blob, filename);
};

export default getFileByRequest;

import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { PPCONTEXT } from './util/ppContext';

const UI_LANGUAGES = ['de', 'fr', 'it'];

const i18NextConfiguration = i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator']
    },
    ns: ['translation'],
    defaultNS: 'translation',
    load: 'languageOnly',
    supportedLngs: UI_LANGUAGES,
    backend: {
      loadPath: `./locales/{{lng}}/{{ns}}.json?v=${new Date().getTime()}`
    }
  });

// TODO jonas 2021-12:
// this variable should not be exported ... but right now it is used in `validationHelpers.ts`... this should be fixed.
export default i18NextConfiguration;

export async function initializeUiLanguage() {
  await i18NextConfiguration;
  i18next.language = getValidUiLanguageFromLocale(i18next.language); // initially this is the detected browser language
  document.documentElement.lang = i18next.language.substring(0, 2);

  // Mit dem Query Parameter `lng=it` kann beim Einstieg in die Webshop-App die Sprache gesetzt werden.
  // Dieser Query Parameter muss dann entfernt werden, weil sonst ein Sprachwechsel aus der App nicht möglich ist,
  // weil beim Sprachwechsel ein Page-Reload gemacht wird und dann die Spache wieder auf die des Parameters gesetzt würde ...
  const url = new URL(window.location.href);
  url.searchParams.delete('lng');
  window.history.replaceState(null, '', url.toString());
}

function getValidUiLanguageFromLocale(currentLocale: string) {
  const shortLocale = currentLocale?.slice(0, 2);
  return UI_LANGUAGES.includes(shortLocale) ? shortLocale : 'de';
}

import React, { useCallback, useContext, useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { StoreContext } from '../StoreContext';
import { useTranslation } from 'react-i18next';
import { TestEnvironmentWarningWrapper } from './TestEnvironmentWarningWrapper';
import CompactHeader from './common/components/header/CompactHeader';
import { Header } from './common/components/header/Header';
import { WebshopErrorBoundary } from './WebshopErrorBoundary';
import { AppContent } from './AppContent';
import ErrorDialog from './shared/components/ErrorDialog';
import LoginTimeoutDialog from './shared/components/LoginTimeoutDialog';
import DeliveryDateSelectionDialog from './cart/components/DeliveryDateSelectionDialog';
import CartItemProblemDialog from './cart/components/cartProblems/CartItemProblemDialog';
import { NotificationsDialog } from '../@tgs-web-ui/notificationcenter';
import OrderingNotAllowedDialog from './shared/components/OKDialog';
import AddToInventoryDialog from './inventory/components/AddToInventoryDialog';
import { CookieConsentBanner } from './common/components/footer/CookieConsentBanner';
import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import notifications from '../notifications';
import { useRouteTrackingForGoogleAnalytics } from '../tracking/googleAnalytics';
import TemplateNameDialog from './templates/components/TemplateNameDialog';
import { TemplateArticleActionsDialog } from './templates/components/TemplateArticleActionsDialog';
import { NewWebshopRedirectDialog } from './shared/components/NewWebshopRedirectDialog';

function AppLayoutComponent() {
  const { userStore, uiStore, masterDataStore } = useContext(StoreContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const isIndexRoute = useMatch('/');

  useRouteTrackingForGoogleAnalytics();

  const showNotification = useCallback(
    (type: any, e: any) => {
      const options = { variant: type };
      enqueueSnackbar(e, options);
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    notifications.on('*', (type: any, e: any) => showNotification(type, e));
  }, [showNotification]);

  const switchLanguage = async (locale: any) => {
    await uiStore.setLanguage(locale);
    window.location.reload();
  };

  const showLanguageSwitch = !!isIndexRoute;

  return (
    <TestEnvironmentWarningWrapper>
      <>
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          {uiStore.isMediumScreenDown ? <CompactHeader showLanguageSwitch={showLanguageSwitch} onSwitchLanguage={switchLanguage} /> : <Header showLanguageSwitch={showLanguageSwitch} onSwitchLanguage={switchLanguage} />}

          <WebshopErrorBoundary>
            <AppContent />
            <ErrorDialog />
            <LoginTimeoutDialog />
            <DeliveryDateSelectionDialog />
            <CartItemProblemDialog />
            <NotificationsDialog
              title={t('Benachrichtigungen')}
              open={masterDataStore.isNotificationDialogOpen}
              notifications={masterDataStore.notifications}
              onClose={() => masterDataStore.closeNotificationDialog()}
              closeText={t('Schliessen')}
            />
            <OrderingNotAllowedDialog
              showDialog={userStore.showOrderingNotAllowedDialog}
              okPressed={() => userStore.updateShowOrderingNotAllowedDialog(false)}
              title={t('Ihr Konto steht leider für Bestellungen momentan nicht zur Verfügung')}
              message={t('Bitte nehmen Sie mit unserem Kundencenter Kontakt auf. Sie erreichen uns wie folgt: Tel: 0848 000 501')}
            />
            <AddToInventoryDialog />
            <TemplateArticleActionsDialog />
            <TemplateNameDialog />
            <NewWebshopRedirectDialog />
          </WebshopErrorBoundary>
        </Box>
        <CookieConsentBanner />
      </>
    </TestEnvironmentWarningWrapper>
  );
}

export const AppLayout = observer(AppLayoutComponent);

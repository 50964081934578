import axios from 'axios';
import { LOCALE_LOADER } from './i18n';
import { TRANSGOURMET_TRACING_ID_KEY } from '../tracking/tracking';
import axiosRetry from 'axios-retry';

axios.defaults.headers.common['Accept'] = 'application/json';

axios.interceptors.request.use((request) => {
  if (request) {
    request.headers['Accept-Language'] = LOCALE_LOADER.getLocale();
    request.headers['Cache-Control'] = 'no-cache,no-store';
    request.headers['Pragma'] = 'no-cache';

    const tracingId = sessionStorage.getItem(TRANSGOURMET_TRACING_ID_KEY);
    if (tracingId) {
      request.headers[TRANSGOURMET_TRACING_ID_KEY] = tracingId;
    }
  }

  return request;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosRetry(axios, {
  retries: 3, // Anzahl der Wiederholungen
  retryDelay: (retryCount) => {
    console.warn(`Retry-Versuch: ${retryCount}`);
    return retryCount * 1000; // Zeit (in ms) zwischen Wiederholungen
  },
  retryCondition: (error) => {
    // Bedingung, wann ein Retry erfolgen soll
    return error.response?.status === 500 || error.code === 'ECONNABORTED';
  }
});

export default axios;

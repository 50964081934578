import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Dialog, DialogContent, DialogTitle, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../../StoreContext';
import { Link } from 'react-router-dom';
import webshopConfig from '../../../config/webshopConfig';

export const NewWebshopRedirectDialog = observer(() => {
  const { userStore } = useContext(StoreContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const showDialog = userStore.showRedirectingToNewWebshopDialog;

  return (
    <Dialog open={showDialog} fullScreen={fullScreen}>
      <DialogTitle>
        <div style={{ textAlign: 'center', color: theme.palette.primary.main }}>
          <Box height={'60px'} p={1}>
            <Link to="/">
              <img src={webshopConfig.logo} alt="" width="200px" />
            </Link>
          </Box>
          {t('Neuer Webshop')}
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="column">
          <Grid item>{t('Sie werden zum neuen Transgourmet Webshop weitergeleitet')} ...</Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
});
